import { createContext, useReducer, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import { ANALYTICS } from 'constants/analytics'
import { Analytics } from 'components/Analytics'
import { createAnalytics, plugins } from '@achieve/cx-event-horizon/browser'
import { isEqual } from 'lodash-es'
const AnalyticsContext = createContext()

const analyticsReducer = (state, action) => {
  switch (action.type) {
    case 'SET_AUTH0_USER':
      return {
        ...state,
        auth0_user_id: action.auth0User.user_id,
        auth0_email: action.auth0User.email,
        profile_id: action.auth0User.profile_id,
      }
    case 'SET_BROWSER_ID':
      return {
        ...state,
        browser_id: action.id,
      }
    case 'SET_PAGE_VARIATION':
      return {
        ...state,
        pageVariation: action.payload.pageVariation,
      }
    case 'SET_SESSION_ID':
      return {
        ...state,
        session_id: action.id,
      }
    case 'SET_TRACKING_DATA':
      return {
        ...state,
        trackingData: action.payload.trackingData,
      }
    case 'CLEAR_TRACKING_DATA':
      return {
        ...state,
        trackingData: state.trackingData,
      }
    case 'SET_PAGE_STATE_LOADING':
      return {
        ...state,
        isPageLoaded: action.payload.isPageLoaded,
      }
    case 'SET_PAGE_STATE_LOADED':
      return {
        ...state,
        trackingData: action.payload.trackingData,
        isPageLoaded: action.payload.isPageLoaded,
      }
    case 'ADD_EVENT_TO_QUEUE':
      return {
        ...state,
        eventQueue: state.eventQueue.concat(action.payload),
      }
    case 'REMOVE_FIRST_EVENT_FROM_QUEUE':
      return {
        ...state,
        eventQueue: state.eventQueue.filter((_, index) => index !== 0),
      }
    case 'CLEAR_EVENT_QUEUE':
      return {
        ...state,
        eventQueue: state.eventQueue,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const useConfig = () => {
  const {
    state: { config },
  } = useContext(AnalyticsContext)
  return { config }
}

const usePageVariation = () => {
  const {
    state: { pageVariation },
  } = useContext(AnalyticsContext)
  return { pageVariation }
}

const initAnalyticsInstance = (config = {}) => {
  const analyticsInstance = createAnalytics({
    application: config.NEXT_PUBLIC_ANALYTICS_APP_NAME,
    debug: parseInt(config.NEXT_PUBLIC_ANALYTICS_DEBUG) === 1 ? true : false,
    plugins: [
      plugins.analyticsFreedom({
        application: config.NEXT_PUBLIC_ANALYTICS_APP_NAME,
        isDebug: parseInt(config.NEXT_PUBLIC_ANALYTICS_DEBUG) === 1 ? true : false,
      }),
      plugins.analyticsTealium({
        application: config.NEXT_PUBLIC_ANALYTICS_APP_NAME,
      }),
    ],
  })
  return { analyticsInstance }
}

const AnalyticsProvider = ({ children, config, pageVariation, pageSectionsVariations }) => {
  // useEffect to dispatch an action to update the pageVariation state whenever the pageVariation prop changes.
  useEffect(() => {
    if (!isEqual(state.pageVariation, pageVariation)) {
      dispatch({
        type: 'SET_PAGE_VARIATION',
        payload: {
          pageVariation,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageVariation])

  const { analyticsInstance } = initAnalyticsInstance(config)
  // The current state. During the first render, it’s set to init(initialArg) or initialArg (if there’s no init).
  // The 2nd argument in the useReducer is just the initial state meaning if we want to update anything in there when data changes
  // we have to manually update the reducer state with a useEffect and dispatch.
  // https://react.dev/reference/react/useReducer
  const [state, dispatch] = useReducer(analyticsReducer, {
    browser_id: Cookies.get(ANALYTICS.BROWSER_ID_KEY_NAME),
    session_id: Cookies.get(ANALYTICS.SESSION_ID_KEY_NAME),
    auth0_user_id: undefined,
    auth0_email: undefined,
    profile_id: undefined,
    isPageLoaded: false,
    eventQueue: [],
    trackingData: {},
    config,
    analyticsInstance,
    pageVariation,
  })
  return (
    <AnalyticsContext.Provider value={{ state, dispatch }}>
      <Analytics
        // pageVariation prop passed down to Analytics component to handle page load events
        pageVariation={pageVariation}
        pageSectionsVariations={pageSectionsVariations}
      >
        {children}
      </Analytics>
    </AnalyticsContext.Provider>
  )
}

export { AnalyticsProvider, AnalyticsContext, useConfig, usePageVariation }

const ANALYTICS = {
  HUB_PROFILE_ID_COOKIE_NAME: 'achieve_profile_id',
  SESSION_ID_KEY_NAME: 'achieve_session_id',
  SESSION_START_EVENTS_TRACKED: 'achieve_session_start_events_tracked',
  BROWSER_ID_KEY_NAME: 'browser_id',
  DATADOG_SITE: 'datadoghq.com',
  SESSION_TIMER_START: 'session_timer_start',
  SESSION_TIMEOUT: 1800000, //30 min in ms
}
Object.freeze(ANALYTICS)
export { ANALYTICS }
